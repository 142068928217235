import logo from "./logo.svg";
import "./App.css";
import {
  App,
  Page,
  Navbar,
  Block,
  NavbarBackLink,
  Toolbar,
  Link,
  Button,
} from "konsta/react";
import React, { useState } from "react";
import {
  Card,
  Input,
  Checkbox,
  Button as MaterialButton,
  Typography,
} from "@material-tailwind/react";

function MyApp() {
  const [isTop, setIsTop] = useState(false);

  return (
    <App theme="ios" className="safe-areas">
      <Page>
        <Navbar title="Winning App" />

        <Toolbar
          top={isTop}
          className={`left-0 ${
            isTop
              ? "ios:top-11-safe material:top-14-safe sticky"
              : "bottom-0 fixed"
          } w-full`}
        >
          <Link toolbar>Home</Link>
          <Link toolbar>History</Link>
          <Link toolbar>Settings</Link>
        </Toolbar>

        <div className="safe-areas">
          <Card color="transparent" shadow={false}>
            <Typography variant="h4" color="blue-gray">
              Sign Up
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              Enter your details to register.
            </Typography>
            <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
              <div className="mb-4 flex flex-col gap-6">
                <Input size="lg" label="Name" />
                <Input size="lg" label="Email" />
                <Input type="password" size="lg" label="Password" />
              </div>
              <Checkbox
                label={
                  <Typography
                    variant="small"
                    color="gray"
                    className="flex items-center font-normal"
                  >
                    I agree the
                    <a
                      href="#"
                      className="font-medium transition-colors hover:text-blue-500"
                    >
                      &nbsp;Terms and Conditions
                    </a>
                  </Typography>
                }
                containerProps={{ className: "-ml-2.5" }}
              />
              <Button className="mt-6" fullWidth>
                Register
              </Button>
              <Typography color="gray" className="mt-4 text-center font-normal">
                Already have an account?{" "}
                <a
                  href="#"
                  className="font-medium text-blue-500 transition-colors hover:text-blue-700"
                >
                  Sign In
                </a>
              </Typography>
            </form>
          </Card>
        </div>
      </Page>
    </App>
  );
}

export default MyApp;
