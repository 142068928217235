import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import MyApp from "./App";
import SignUp from "./pages/customer/SignUp";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-tailwind/react";
import SignIn from "./pages/customer/SignIn";
import Home from "./pages/customer/Home";
import Outlet from "./pages/customer/Outlet";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      {/* <MyApp /> */}
      {/* <SignUp /> */}
      {/* <SignIn /> */}
      {/* <Home /> */}
      <Outlet />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
